<template>
  <div class="pt-8">
    <form class="m-auto px-10 pb-5 pt-6 z-10 relative shadow-lg bg-gray-100">
      <label for="brand-selector" class="block mb-2 text-sm"
        >Environment *</label
      >
      <select
        v-model="selectedBrand"
        id="brand-selector"
        class="block border-solid border rounded border-gray-600 w-full p-3 text-sm bg-gray-100 hover:border-blue-700 focus:border-blue-700"
        @change="setCurrentConfig"
        data-cy="dropdown"
      >
        <option
          v-for="option in options"
          :key="option.brandName"
          :data-cy="option.brandName"
          >{{ option.brandName }}</option
        >
      </select>
    </form>
    <amplify-authenticator :key="selectedBrand">
      <amplify-sign-up
        slot="sign-up"
        username-alias="email"
        header-text="Sign Up"
        :form-fields.prop="formFields"
      >
      </amplify-sign-up>
      <amplify-sign-in
        header-text="Sign In"
        slot="sign-in"
        data-cy="sign-in"
        class="relative authenticator"
      >
      </amplify-sign-in>
    </amplify-authenticator>
    <div>
      <p class="error" v-if="errorAppNameMsg"> {{errorAppNameMsg}} </p>
      <p class="error" v-if="errorLocaleMsg"> {{errorLocaleMsg}} </p>
    </div>
  </div>
</template>

<script>
import AWSConfigOptions from '@/util/AWSConfigOptions';
import configureAmplify from '@/util/configureAmplify';
import isLocal from '@/util/isItLocal';

export default {
  data () {
    return {
      errorAppNameMsg: '',
      errorLocaleMsg: '',
      selectedBrand: 'Oral-B NA',
      configurations: AWSConfigOptions,
      currentConfig: null,
      formFields: [
        {
          type: 'email',
          label: 'Email Address *',
          placeholder: 'Email',
          required: true
        },
        {
          type: 'password',
          label: 'Password*',
          placeholder: 'Password',
          required: true
        },
        {
          type: 'locale',
          label: 'Locale (optional)',
          placeholder: 'Ex: en-US',
          required: false
        },
        {
          type: 'website',
          label: 'AppName (optional)',
          placeholder: 'Ex: sonos',
          required: false
        }
      ]
    };
  },
  created () {
    this.setCurrentConfig();
  },
  mounted () {
    this.sleep(500).then(() => {
      this.setSigninListeners();
    });
  },
  computed: {
    options () {
      return this.configurations.filter(
        config => config.brandName !== undefined
      );
    }
  },
  methods: {
    setSigninListeners () {
      // set create account link listener for when user clicks sign up
      const createAccountLink = document.querySelector('body > div > div.flex.flex-col.items-center.justify-center.h-full.main-view.bg-theme-lightest.px-24 > div > amplify-authenticator > amplify-sign-in').shadowRoot.querySelector('amplify-form-section > form > amplify-section > section > div:nth-child(4) > div > slot > slot > span > amplify-button > button');

      if (!createAccountLink.getAttribute('listener')) {
        createAccountLink.addEventListener('click', () => {
          this.sleep(1000).then(() => {
            this.setSignUpListeners();
          });
        });
        createAccountLink.setAttribute('listener', 'true');
      }
    },
    setSignUpListeners () {
      // this means amplify ui sign up form is on page and we can now grab the fields needed
      const signupLocaleField = document.querySelector('body > div > div.flex.flex-col.items-center.justify-center.h-full.main-view.bg-theme-lightest.px-24 > div > amplify-authenticator > amplify-sign-up').shadowRoot.querySelector('amplify-form-section > form > amplify-section > section > amplify-auth-fields > div > amplify-form-field:nth-child(3) > div > div:nth-child(2) > slot-fb > amplify-input > input');
      const signupAppNameField = document.querySelector('body > div > div.flex.flex-col.items-center.justify-center.h-full.main-view.bg-theme-lightest.px-24 > div > amplify-authenticator > amplify-sign-up').shadowRoot.querySelector('amplify-form-section > form > amplify-section > section > amplify-auth-fields > div > amplify-form-field:nth-child(4) > div > div:nth-child(2) > slot-fb > amplify-input > input');
      const signUpBtn = document.querySelector('body > div > div.flex.flex-col.items-center.justify-center.h-full.main-view.bg-theme-lightest.px-24 > div > amplify-authenticator > amplify-sign-up').shadowRoot.querySelector('amplify-form-section > form > amplify-section > section > div:nth-child(3) > div > slot > slot:nth-child(2) > amplify-button > button');

      signupLocaleField.addEventListener('blur', () => {
        this.validateSignupLocaleField(signupLocaleField.value, signupAppNameField.value);
      });

      signupAppNameField.addEventListener('blur', () => {
        this.validateSignupAppNameField(signupLocaleField.value, signupAppNameField.value);
      });

      signUpBtn.addEventListener('mouseover', () => {
        this.validateSignupAppNameField(signupLocaleField.value, signupAppNameField.value);
      });
    },
    validateSignupLocaleField (signupLocaleField, signupAppNameField) {
      if (signupLocaleField && this.verifyLocaleFormat(signupLocaleField)) {
        const countryCode = signupLocaleField.split('-')[1];
        signupAppNameField && this.verifyLocalAppName(countryCode, signupAppNameField);
      }
    },
    validateSignupAppNameField (signupLocaleField, signupAppNameField) {
      if (signupLocaleField && signupAppNameField) {
        const countryCode = signupLocaleField.split('-')[1];
        this.verifyLocalAppName(countryCode, signupAppNameField);
      }
    },
    sleep (ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    verifyLocaleFormat (locale) {
      const convertLocale = locale.split('-');
      if (convertLocale.length !== 2) {
        this.errorLocaleMsg = 'Incorrect Locale length or format. Locale format: [two digit language code]-[two digit country code] Ex: en-US';
        return false;
      } else if (convertLocale[0].length !== 2) {
        this.errorLocaleMsg = `Language code ${convertLocale[0]} incorrect length. Locale format: [two digit language code]-[two digit country code] Ex: en-US`;
        return false;
      } else if (convertLocale[1].length !== 2) {
        this.errorLocaleMsg = `Country code ${convertLocale[1]} incorrect length. Locale format: [two digit language code]-[two digit country code] Ex: en-US`;
        return false;
      }
      this.errorLocaleMsg = '';
      return true;
    },
    async verifyLocalAppName (countryCode, appName) {
      const res = await fetch(`https://94t50fcemh.execute-api.us-east-1.amazonaws.com/api/v3/verification?countryCode=${countryCode.toUpperCase()}&appName=${appName}`);
      const data = await res.json();
      console.log(data);
      if (!data) {
        this.errorAppNameMsg = `${appName} does not exist in country code: ${countryCode}`;
        return false;
      }
      this.errorAppNameMsg = '';
    },
    async setCurrentConfig () {
      // find current brand from awsOptions and ENV variables
      const selectedBrand = this.configurations.find(
        config => config.brandName === this.selectedBrand
      );
      // make fetch request to discovery api with token
      // for testing new brands in QA, use the first discovery endpoint
      // once backend changes hit prod, the second discovery endpoint (alchemy.codes) works for all envs
      const res = await fetch(`https://pgdiscovery.com/api/v3/app?countryCode=${selectedBrand.locale}`, {
        method: 'GET',
        headers: {
          authorization: `Bearer ${selectedBrand.token}`
        }
      });
      const data = await res.json();
      // set our current config based on results we get back from discovery api
      this.currentConfig = {
        brandName: data.brandName,
        aws_appsync_graphqlEndpoint: data.apiUrl,
        aws_appsync_region: data.region,
        aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
        aws_cognito_identity_pool_id: data.identityPoolId,
        region: data.region,
        aws_user_pools_id: data.userPoolId,
        aws_user_pools_web_client_id: data.webClientId
      };
      // set cookies/localStorage in browser
      this.$cookies.set(
        'ampConfig',
        this.currentConfig,
        null,
        null,
        null,
        !isLocal
      );
      localStorage.setItem('currentBrand', this.selectedBrand);

      // configure amplify
      configureAmplify(this.currentConfig);
      this.setSigninListeners();
      this.errorAppNameMsg = '';
      this.errorLocaleMsg = '';
    }
  }
};
</script>

<style lang="scss">
.authenticator {
  bottom: 55px;
}
.error {
  color: red;
  font-style: italic;
  font-size: 14px;
}
form {
  max-width: 460px;
}
.form-section-header{
  margin: 0px;
}

</style>
